<footer class="fiber-bg-blue-primary ga-footer">
    <div class="fiber-grid resp-margin">
        <div class="fiber-grid__row">
            <div class="fiber-grid__col fiber-grid__col--md-12 fiber-pb-4 fiber-pb-4 fiber-text--color-white">
                <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center">  
                     <a href="{{legalandPrivacyURL}}" target="_blank">Legal and Privacy</a> 
                    <!-- for Blue Cross and Blue Shield plans in  <a href="{{ilLegalURL}}" target="_blank">Illinois</a>,<a href="{{mtLegalURL}}" target="_blank"> Montana</a>,<a href="{{nmLegalURL}}" target="_blank"> New Mexico</a>,<a href="{{okLegalURL}}" target="_blank"> Oklahoma</a>,<a href="{{txLegalURL}}" target="_blank"> Texas</a>        -->
                </p>
                <!-- <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center">  
                    Non-Discrimination Notice for Blue Cross and Blue Shield plans in  <a href="{{ilDiscrimationURL}}" target="_blank">Illinois</a>,<a href="{{mtDiscrimationURL}}" target="_blank"> Montana</a>,<a href="{{nmDiscrimationURL}}" target="_blank"> New Mexico</a>,<br/>
                    <a href="{{okDiscrimationURL}}" target="_blank"> Oklahoma</a>,<a href="{{txDiscrimationURL}}" target="_blank"> Texas</a>       
                </p> -->
               <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center">
    Blue Cross and Blue Shield of Illinois, Blue Cross and Blue Shield of Montana, Blue Cross and Blue Shield of New Mexico, Blue Cross and Blue Shield of Oklahoma, Blue Cross and Blue Shield of Texas, Divisions of Health Care Service Corporation, a Mutual Legal Reserve Company,
     an Independent Licensee of the Blue Cross and Blue Shield Association
    
    </p>
    <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center">  © Copyright {{currentYear}} Health Care Service Corporation. All Rights Reserved.
    </p>

    <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center"><span class="fiber-icon--name-document-pdf-solid fiber-icon--size-1 fiber-mr-1"></span>File is in portable document format (PDF). To view this file, you may need to install a PDF reader program. Most PDF readers are a free download. One option is Adobe® Reader® which has a built-in reader. You can download other tools and learn more about accessibility at <a href="https://www.adobe.com/accessibility.html" target="_blank">adobe.com</a> <span class="fiber-icon--name-external-link fiber-icon--size-1 fiber-icon--color-white"></span></p>
    <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center"><span class="fiber-icon--name-external-link fiber-icon--size-1 f fiber-mr-1"></span>You are leaving this website/app (“site”). The new site may be offered by a vendor or an independent third party. The site may also contain non-Medicare related information. Some sites may require you to agree to their terms of use and privacy policy.</p>
    </div>

        </div>
    </div>

</footer>

export const environment = {
  production: false,
  title: 'uat',
  NODE_ENV:'uat',
  url: 'https://groupauthenticatorproducer.test.bcbs-inmot.com/',

  AM_URL: "https://bim.test.bcbs-inmot.com/am",


  REALM_PATH: 'B2BApps',
  DEFAULT_TREE: 'HCSC-Login-Future_State',
  FORGOT_PASSWORD_TREE: 'HCSC-Forgot-Password_Future_State',
  FORGOT_USERID_TREE : 'Future-State-Forgot-Username',

  BAP_LEGAL_IL:'https://www.bcbsil.com/legal-and-privacy',
  BAP_LEGAL_TX:'https://www.bcbstx.com/legal-and-privacy',
  BAP_LEGAL_MT:'https://www.bcbsmt.com/legal-and-privacy',
  BAP_LEGAL_OK:'https://www.bcbsok.com/legal-and-privacy',
  BAP_LEGAL_NM:'https://www.bcbsnm.com/legal-and-privacy',

  BAP_NON_DISCRIMINATION_IL: 'https://www.bcbsil.com/legal-and-privacy/non-discrimination-notice',
  BAP_NON_DISCRIMINATION_MT: 'https://www.bcbsmt.com/legal-and-privacy/non-discrimination-notice',
  BAP_NON_DISCRIMINATION_NM: 'https://www.bcbsnm.com/legal-and-privacy/non-discrimination-notice',
  BAP_NON_DISCRIMINATION_OK: 'https://www.bcbsok.com/legal-and-privacy/non-discrimination-notice',
  BAP_NON_DISCRIMINATION_TX: 'https://www.bcbstx.com/legal-and-privacy/non-discrimination-notice',
  BAP_LEGAL_AND_PRIVACY: 'https://www.bcbsil.com/producer/legal-and-privacy-pep',

  //Terms of Use
  BAP_TOU : 'https://bim.test.bcbs-inmot.com/legal-and-privacy/content',

  BAP_FORWARD : 'https://www.producer.test.bcbs-inmot.com/ProducerPortal',

  //TOu Headers
  BAP_TOU_Authenticated : 'false',
  BAP_TOU_CLIENTID: 'oauth_producerexperienceportal_ga_app00236630',
  BAP_TOU_DOCUMENTNAME: 'Terms of Use',
  BAP_TOU_LPC_CLIENTID: 'oauth_producerexperienceportal_ga_app00046983',
  BAP_TOU_REALM : 'producer',
  BAP_TOU_STATE: 'all-states',
  BAP_TOU_ACCEPTLANG: 'en',
  BAP_TOU_Access_Control_Allow_Origin : 'https://groupauthenticatorproducer.test.bcbs-inmot.com',
  BAP_TOU_Access_Control_Allow_Methods :'GET',
  BAP_TOU_Access_Control_Allow_Headers : 'application/json',
  BAP_TOU_X_Requested_With : '*',

 BAP_RESEND_EMAIL: 'https://bim.hcsctest.net/openidm/endpoint/hcscSendVerificationEmail?_action=sendVerification',
 BAP_RESEND_RESEND_Verification_Email :'https://bim.test.bcbs-inmot.com/ServiceLayer/public/v1',
 //  BAP_RESEND_RESEND_Verification_Email :'https://bim.hcsctest.net/ServiceLayer/public/v1',
  BAP_VERIFY_Email_API : 'https://bim.test.bcbs-inmot.com/ServiceLayer/public/v1/custom/verify-email',
 //  BAP_VERIFY_Email_API : 'https://bim.hcsctest.net/ServiceLayer/public/v1/custom/verify-email',

 GAP_Service_RESTURI: 'https://t-app00046899-ig-uat-wau-svc.test.hcscint.net/producerexperience/v1/contacts/validateProducer',
 GAP_JWT_Service_RESTURI: 'https://t-app00046899-ig-uat-wau-svc.test.hcscint.net/sts/v5/jwt_token_internal',


  //Registeration
 REGISTER_URL_BAP : 'https://bim.hcsctest.net/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-Future-State-Registration-SelfService',
 BAP_APP_ID: 'APP00236630',
 CORP_CODE: 'NA1',
 PROFILE_ID : '00eWD000000DUujYAG',
 BAP_ROLE : 'Future State Agent'

};

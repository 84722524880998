import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainService {
 private GET_JWT_TOKEN_URL = '/api/fetchJWTToken'
 private Verify_Producer_URL = '/api/verifyProducerDetails'
  constructor( private httpClient : HttpClient) { }
  private handleError(error: HttpErrorResponse) {
    return of(error);
  }
  getBapTermsData(){
    let httpOptions = {
      headers : new HttpHeaders({
        'authenticated' : environment.BAP_TOU_Authenticated,
        'clientid' : environment.BAP_TOU_CLIENTID,
        'documentname' : environment.BAP_TOU_DOCUMENTNAME,
        'lpcclientid' : environment.BAP_TOU_LPC_CLIENTID,
        'realm' : environment.BAP_TOU_REALM,
        'state' : environment.BAP_TOU_STATE,
        'accept-language' : environment.BAP_TOU_ACCEPTLANG,
        'Access-Control-Allow-Origin': environment.BAP_TOU_Access_Control_Allow_Origin,
        'Access-Control-Allow-Methods': environment.BAP_TOU_Access_Control_Allow_Methods,
        'Access-Control-Allow-Headers': environment.BAP_TOU_Access_Control_Allow_Headers,
        'X-Requested-With': environment.BAP_TOU_X_Requested_With
      })
    }

   return this.httpClient.get(environment.BAP_TOU , httpOptions );

  }

  resendBAPEmail(userName:any) {
    const parameters = new HttpParams()


    .set("userName", userName)
    .set("resendVerification", true)
    .set("url" , environment.BAP_RESEND_EMAIL)
    return this.httpClient.get("/groupauthenticatorproducer/sendEmail", { params: parameters });

  }
   getJWTTokenData(){
     let params = new HttpParams()
     .set("restUri" , environment.GAP_JWT_Service_RESTURI)
     return this.httpClient.get(this.GET_JWT_TOKEN_URL , { params: params }).pipe(catchError(this.handleError));;
   }
  verifyProducerDetails(data: any){
    let params = new HttpParams()
    .set("restUri" , environment.GAP_Service_RESTURI)
    .set("client_id" , '')
    .set("client_secret", '')
      .set("jwtUri", environment.GAP_JWT_Service_RESTURI)

     return this.httpClient.post(this.Verify_Producer_URL , data , { params: params } ).pipe(catchError(this.handleError));
  }



  postDataByUrl(body: any ,url:any){
    return this.httpClient.post(url,body);
  }

  postApiCall(body: any ,url:any): Observable<HttpResponse<any>>{
    return this.httpClient.post<any>(url,body,{observe: 'response'});
  }
}
